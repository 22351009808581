<template>
  <div class="navbar-container d-flex justify-content-between content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <div class="mr-1">
      {{ $store.state.filterPlus.tableIndex == null ? "" : $store.state.filterPlus.tableIndex + 1 + "/" }}
      {{ $store.state.pageData.dataCount }}
    </div>
    <div v-if="$route.meta.filterTemplate" class="mr-1">
      <v-select
        @input="filter($event)"
        v-model="selectedFilter"
        :options="$route.meta.filterTemplate"
        label="name"
        :reduce="(option) => option.data"
        :clearable="false"
        appendToBody
        style="min-width: 200px; width: auto"></v-select>
    </div>
    <div v-if="$route.meta.filterDynamic" class="mr-1">
      <v-select
        @input="filterDynamic($event)"
        :options="$route.meta.filterDynamic"
        label="name"
        :reduce="(option) => option.data"
        :clearable="false"
        appendToBody
        style="min-width: 200px; width: auto"></v-select>
    </div>
    <div v-if="currentRoute !== '/'">
      <b-button variant="outline-primary" class="btn-icon mr-1" @click="$store.commit('REFRESH_DATA', true)">
        <feather-icon icon="RefreshCwIcon" />
      </b-button>
    </div>
    <div v-if="currentRoute !== '/'">
      <div v-if="currentRoute !== '/city/villages/index'">
        <div class="d-flex justify-content-beetwen">
          <b-button
            v-b-tooltip.hover.bottom="'Фильтр'"
            variant="outline-primary"
            class="btn-icon mr-1"
            v-b-modal="this.$route.name + 'filter'">
            <feather-icon icon="FilterIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.bottom="'Фильтр+'"
            variant="outline-primary"
            class="btn-icon mr-1"
            v-b-modal="this.$route.name + 'filter'"
            @click="add()">
            <feather-icon icon="FilterIcon" />
            <feather-icon icon="PlusIcon" size="10" />
          </b-button>
          <b-button
            v-if="currentRoute !== '/taxi/photo-control/index'"
            v-b-tooltip.hover.bottom="'Добавить'"
            variant="outline-primary"
            class="btn-icon mr-1"
            v-b-modal="$route.name + 'CreateModal'">
            <feather-icon icon="PlusIcon" />
          </b-button>

          <b-button
            v-b-tooltip.hover.bottom="'Отправка смс'"
            v-if="currentRoute === '/taxi/driver'"
            variant="outline-primary"
            class="btn-icon mr-1"
            v-b-modal.ModalMail>
            <feather-icon icon="MailIcon" />
          </b-button>

          <b-button
            v-b-tooltip.hover.bottom="'Отправка смс'"
            v-if="currentRoute === '/order/index' || currentRoute === '/client'"
            variant="outline-primary"
            class="btn-icon mr-1"
            v-b-modal.ModalSend>
            <feather-icon icon="MailIcon" />
          </b-button>

          <!-- <download-excel
            v-b-tooltip.hover.bottom="'Экспорт в Excel'"
            v-if="currentRoute === '/order/index'"
          :name="`Заказы_${currentDate}.xls`"
          :data="$store.state.excel.orders"
          :fields="json_fields"
        >
          <b-button
            variant="outline-primary"
            class="btn-icon mr-1"
          >
            <i class="ri-file-excel-2-line"></i>
          </b-button>
        </download-excel> -->

          <!-- <div v-if="currentRoute === '/taxi/driver/index'">
            <b-button
              variant="outline-primary"
              class="btn-icon mr-1"
              v-b-modal.balanceModal
            >
              <feather-icon icon="TrendingUpIcon" />
            </b-button>
            <b-button
              variant="outline-primary"
              class="btn-icon mr-1"
              v-b-modal.chargeModal
            >
              <feather-icon icon="TrendingDownIcon" />
            </b-button>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="currentRoute === '/taxi/driver' || currentRoute === '/client'">
      <b-button
        variant="outline-primary"
        v-b-modal.ModalPush
        class="btn-icon mr-1"
        v-b-tooltip.hover.bottom="'Push-нотивикация'">
        <feather-icon icon="MailIcon" />
      </b-button>
    </div>
    <div v-if="this.$store.state.draggableTab.tabs.length > 5">
      <b-button
        variant="outline-primary"
        class="btn-icon mr-1"
        @click="clear()"
        v-b-tooltip.hover.bottom="'Очистить историю'">
        <feather-icon icon="DeleteIcon" />
      </b-button>
    </div>
    <div v-if="this.$store.state.excel.orders.length > 0">
      <b-button
        variant="outline-primary"
        v-b-modal.ModalExcel
        class="btn-icon mr-1"
        @click="exportExcel()"
        v-b-tooltip.hover.bottom="'Экспорт в Excel'">
        <i class="ri-file-excel-2-line"></i>
      </b-button>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <!-- <b-form-checkbox
      class="custom-control-success user-select-none"
      :checked="$store.state.caller.status"
      @input="$store.commit('caller/UPDATE_STATUS', !$store.state.caller.status)"
      name="check-button"
      switch
      inline
      :value="true"
      >
        SIP
      </b-form-checkbox>
      <caller-Toggler /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar />  -->
      <!-- <cart-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import CallerToggler from "./components/CallerToggler.vue";
import vSelect from "vue-select";
import exceljs from "exceljs";

export default {
  components: {
    BLink,
    CallerToggler,
    // Navbar Components
    BNavbarNav,
    DarkToggler,
    CartDropdown,
    UserDropdown,
    vSelect,
  },
  data() {
    return {
      keydownHandler: null,
      currentRoutes: this.$route.fullPath,
      currentDate: new Date().toISOString().replaceAll("-", "").replaceAll(":", "").replaceAll("T", "_").slice(0, 13),
      selectedFilter: null,
      json_fields: {
        ID: { callback: (value) => value.id },
        Подразделения: {
          callback: (value) => (value.division ? value.division : ""),
        },
        "Номер телефона": {
          callback: (value) => (value.phone ? value.phone : ""),
        },
        Откуда: {
          callback: (value) => (value.from_address == null ? "" : value.from_address.name),
        },
        Куда: {
          callback: (value) => {
            let joinedAdresses = "";
            if (value.to_addresses.length != 0) {
              value.to_addresses.forEach((address, index) => {
                joinedAdresses += address.name + "; ";
              });
            }
            return joinedAdresses;
          },
        },
        Цена: { callback: (value) => (value.price ? value.price : "") },
        Статус: { callback: (value) => (value.status ? value.status : "") },
        Тип: { callback: (value) => (value.type ? value.type : "") },
        Тариф: {
          callback: (value) => (value.tariff == null ? "" : value.tariff),
        },
        "На время": {
          callback: (value) => (value.date_time == null ? "" : value.date_time),
        },
        "Время поступления": {
          callback: (value) => (value.created_at == null ? "" : value.created_at),
        },
      },
    };
  },
  methods: {
    filter($event) {
      localStorage.setItem(this.$route.name + "data", JSON.stringify($event));
      this.$store.commit("REFRESH_DATA", true);
    },
    filterDynamic($event) {
      localStorage.setItem(this.$route.name + "data", JSON.stringify($event));
      this.$store.commit("REFRESH_DATA", true);
    },
    add() {
      this.$store.state.filterPlus.addFilterPlus = true;
    },
    clear() {
      this.$router.push({ path: "/" });
      this.$store.commit("draggableTab/CLEAR_TAB");
      this.$store.commit("excel/SET_ORDERS", []);
    },
    async exportExcel() {
      const excel = new exceljs.Workbook();
      const sheet = excel.addWorksheet("Orders");
      sheet.columns = this.$store.state.excel.field.map((field) => {
        return {
          header: field.key,
          key: field.label,
          ...field,
        };
      });
      this.$store.state.excel.orders.forEach((order) => {
        sheet.addRow(order);
      });
      const buffer = await excel.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Orders_${this.currentDate}.xlsx`;
      link.click();
    },
  },
  computed: {
    currentRoute() {
      return this.currentRoutes;
    },
  },
  watch: {
    $route(to, from) {
      this.currentRoutes = to.fullPath;
      if (from.fullPath !== to.fullPath) {
        this.$store.commit("SET_FILTER_INDEX", null);
      }
    },
  },
  mounted() {
    this.keydownHandler = (e) => {
      if ((e.which || e.keyCode) == 116 && !e.ctrlKey && !e.altKey) {
        e.preventDefault();
        this.$store.commit("REFRESH_DATA", true);
      }
    };
    document.body.addEventListener("keydown", this.keydownHandler);
    let filterData = localStorage.getItem(this.$route.name + "data");
    if (filterData) {
      this.selectedFilter = JSON.parse(filterData);
    } else {
      this.selectedFilter = {
        limit: 100,
      };
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  beforeDestroyed() {
    document.body.removeEventListener("keydown", this.keydownHandler);
    localStorage.removeItem(this.$route.name + "data");
    this.$store.commit("SET_FILTER_INDEX", null);
    this.clear();
  },
};
</script>
