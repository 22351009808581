export default [
  {
    title: "Аналитика",
    route: "dashboard",
    icon: "ri-bar-chart-2-line",
    children: [
      {
        title: "Количество заказов",
        route: "dashboard-order",
        icon: "ri-subtract-line",
      },
      {
        title: "Запросы на заказы",
        route: "dashboard-requests",
        icon: "ri-subtract-line",
      },
      {
        title: "Переход по QR",
        route: "dashboard/qr-cod",
        icon: "ri-subtract-line",
      },
      {
        title: "Процесс регистрации водителей",
        route: "driversAnalytic",
        icon: "ri-subtract-line",
      },
      {
        title: "Переход по QR с типами",
        route: "slags/count",
        icon: "ri-subtract-line",
      },
    ],
  },
  {
    title: "Такси",
    route: "taxi",
    icon: "ri-taxi-line",
    children: [
      {
        title: "Заказы",
        route: "orders",
        icon: "ri-subtract-line",
      },
      {
        title: "Заказы (журнал)",
        route: "orders/journals",
        icon: "ri-subtract-line",
      },
      {
        title: "Карта заказов",
        route: "orders-map/orders",
        icon: "ri-subtract-line",
      },
      {
        title: "История звонков",
        route: "asterisk-api/history-call",
        icon: "ri-subtract-line",
      },
      {
        title: "Анкеты",
        route: "driver-profiles",
        icon: "ri-subtract-line",
      },
      {
        title: "Водители",
        route: "drivers",
        icon: "ri-subtract-line",
      },
      {
        title: "Автомобили",
        route: "cars",
        icon: "ri-subtract-line",
      },
      {
        title: "Состояние автомобилей",
        route: "car-state",
        icon: "ri-subtract-line",
      },
      {
        title: "Cостояние автомобилей (текущие)",
        route: "car-state/current",
        icon: "ri-subtract-line",
      },
      {
        title: "Журнал клиентов",
        route: "clients",
        icon: "ri-subtract-line",
      },
      {
        title: "Служебные записки",
        route: "memos",
        icon: "ri-subtract-line",
      },
      {
        title: "Фотоконтроль",
        route: "photo-control/performer-assign/search",
        icon: "ri-subtract-line",
      },
      {
        title: "Запросы заказов",
        route: "report/assigment-log",
        icon: "ri-subtract-line",
      },
    ],
  },
  {
    title: "Документы",
    route: "documentation",
    icon: "ri-account-box-line",
    children: [
      {
        title: "История платежей",
        route: "documents/performers-transactions",
        icon: "ri-subtract-line",
      },
      {
        title: "Активация промокода",
        route: "promo-code/activations",
        icon: "ri-subtract-line",
      },
      {
        title: "Журнал Промокоды",
        route: "promo-code",
        icon: "ri-subtract-line",
      },
      {
        title: "Типы промокодов",
        route: "promo-code/type",
        icon: "ri-subtract-line",
      },
    ],
  },
  {
    title: "Города",
    route: "city",
    icon: "ri-building-2-line",
    children: [
      {
        title: "Дома",
        route: "admin/address",
        icon: "ri-subtract-line",
      },
      {
        title: "Страны",
        route: "addresses/countries",
        icon: "ri-subtract-line",
      },
      {
        title: "Регионы",
        route: "addresses/regions",
        icon: "ri-subtract-line",
      },
      {
        title: "Города",
        route: "addresses/cities",
        icon: "ri-subtract-line",
      },
      {
        title: "Населенные пункты",
        route: "addresses/villages",
        icon: "ri-subtract-line",
      },
      {
        title: "Тип населенных пунктов",
        route: "addresses/village/type",
        icon: "ri-subtract-line",
      },
      {
        title: "Зоны",
        route: "addresses/zones",
        icon: "ri-subtract-line",
      },
      {
        title: "Районы",
        route: "addresses/districts",
        icon: "ri-subtract-line",
      },
      {
        title: "Улицы",
        route: "addresses/streets",
        icon: "ri-subtract-line",
      },
      {
        title: "Типы улиц",
        route: "addresses/street-type",
        icon: "ri-subtract-line",
      },
      {
        title: "Адреса",
        route: "addresses/addresses",
        icon: "ri-subtract-line",
      },
      {
        title: "Не найденные адреса",
        route: "addresses/not_found",
        icon: "ri-subtract-line",
      },
      {
        title: "Быстрые адреса",
        route: "addresses/fast/addresses",
        icon: "ri-subtract-line",
      },
      {
        title: "Категория быстрых адресов",
        route: "addresses/fast/addresses/categories",
        icon: "ri-subtract-line",
      },
    ],
  },
  {
    title: "Настройка системы",
    route: "tariff",
    icon: "ri-settings-2-line",
    children: [
      {
        title: "Бренд организации",
        route: "SystemSettings/brands",
        icon: "ri-subtract-line",
      },
      {
        title: "Причина отмены",
        route: "settings-order/cancel-reason",
        icon: "ri-subtract-line",
      },
      {
        title: "Время обработки форм",
        route: "time-form",
        icon: "ri-subtract-line",
      },
      {
        title: "Доп. опции для водителя",
        route: "car-settings/dop-options",
        icon: "ri-subtract-line",
      },
      {
        title: "Категория автомобиля",
        route: "car-settings/categories",
        icon: "ri-subtract-line",
      },
      {
        title: "Категория нарушения",
        route: "categoryviolation",
        icon: "ri-subtract-line",
      },
      {
        title: "Класс автомобиля",
        route: "car-settings/classes",
        icon: "ri-subtract-line",
      },
      {
        title: "Комиссия",
        route: "commission",
        icon: "ri-subtract-line",
      },
      {
        title: "Модель автомобиля",
        route: "car-settings/model-cars",
        icon: "ri-subtract-line",
      },
      {
        title: "Настройки надбавок клиентского приложения",
        route: "tariff/clientsTypes",
        icon: "ri-subtract-line",
      },
      {
        title: "Надбавка",
        route: "tariff/allowances",
        icon: "ri-subtract-line",
      },
      {
        title: "Надбавка версия 2",
        route: "v2/allowances",
        icon: "ri-subtract-line",
      },
      {
        title: "Организации",
        route: "organizations",
        icon: "ri-subtract-line",
      },
      {
        title: "Подразделение",
        route: "divisions",
        icon: "ri-subtract-line",
      },
      {
        title: "Причина обработки",
        route: "driver-profile-causes",
        icon: "ri-subtract-line",
      },
      {
        title: "Причина отмены",
        route: "orders-reason/orders",
        icon: "ri-subtract-line",
      },
      {
        title: "Причина обработки (СЗ)",
        route: "memo-causes",
        icon: "ri-subtract-line",
      },
      {
        title: "Состояние автомобиля",
        route: "car-settings/car-conditions",
        icon: "ri-subtract-line",
      },
      {
        title: "Тарифы водителей",
        route: "main",
        icon: "ri-subtract-line",
      },
      {
        title: "Тарифы клиентов",
        route: "main/tariff/client",
        icon: "ri-subtract-line",
      },
      {
        title: "Тип документа",
        route: "type-transaction",
        icon: "ri-subtract-line",
      },
      {
        title: "Тип кузова",
        route: "car-settings/body-types",
        icon: "ri-subtract-line",
      },
      {
        title: "Тип расчета",
        route: "tariff/calc-types",
        icon: "ri-subtract-line",
      },
      {
        title: "Тип запроса исполнителя",
        route: "performer-request-types",
        icon: "ri-subtract-line",
      },
      {
        title: "Категория тарифа",
        route: "category-tariff",
        icon: "ri-subtract-line",
      },
      {
        title: "Тема служебной записки",
        route: "memo-subjects",
        icon: "ri-subtract-line",
      },
      {
        title: "Тип служебной записки",
        route: "memo-types",
        icon: "ri-subtract-line",
      },
      {
        title: "Тип тарифа",
        route: "tariff/types",
        icon: "ri-subtract-line",
      },
      {
        title: "Тип фотоконтроля",
        route: "photo-control/type",
        icon: "ri-subtract-line",
      },
      {
        title: "Цвет автомобиля",
        route: "car-settings/car-colors",
        icon: "ri-subtract-line",
      },
      {
        title: "Бренд автомобиля",
        route: "car-settings/brands",
        icon: "ri-subtract-line",
      },
      {
        title: "Тип организации",
        route: "orgtype",
        icon: "ri-subtract-line",
      },
      {
        title: "Статусы для анкеты",
        route: "driver-profile-statuses",
        icon: "ri-subtract-line",
      },
      {
        title: "Шаблон надбавки",
        route: "v2/allowances/pattern",
        icon: "ri-subtract-line",
      },
      {
        title: "Управление версиями",
        route: "app-versions",
        icon: "ri-subtract-line",
      },
      {
        title: "Шаблоны сообщений",
        route: "message-templates",
        icon: "ri-subtract-line",
      },
      {
        title: "Категория POI",
        route: "poicategories",
        icon: "ri-subtract-line",
      },
      {
        title: "Типы Slug",
        route: "slag-list",
        icon: "ri-subtract-line",
      },
    ],
  },
  {
    title: "Персонал",
    route: "staff",
    icon: "ri-account-box-line",
    children: [
      {
        title: "Учетная запись",
        route: "employee",
        icon: "ri-subtract-line",
      },
      {
        title: "Отдел сотруд.",
        route: "employee-group",
        icon: "ri-subtract-line",
      },
      {
        title: "Должности",
        route: "position",
        icon: "ri-subtract-line",
      },
      {
        title: "Разделы",
        route: "routes",
        icon: "ri-subtract-line",
      },
      {
        title: "Доступы",
        route: "access",
        icon: "ri-subtract-line",
      },
    ],
  },
];
