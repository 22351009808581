<template>
  <ul id="app">
    <li class="resizable-x">
      <div class="resizable-y" style="flex: 100%">
        <div class="div1" style="flex: 80%; overflow-y: scroll">
          <component
            :is="resolveNavItemComponent(item)"
            v-for="item in items"
            :key="item.header || item.title"
            :item="item" />
          <li class="nav-item" @click="logout">
            <a class="d-flex align-items-center">
              <div><i style="font-size: 23px; margin-left: 10px" class="ri-logout-box-r-line"></i></div>
              <span class="menu-title text-truncate">Выход</span>
            </a>
          </li>
        </div>
        <div class="resizer-y"></div>
        <div class="div2" style="flex: 20%">
          <b-form-checkbox
            class="custom-control-success user-select-none"
            v-model="$store.state.caller.status"
            name="check-button"
            switch
            inline
            :value="true"
            :disabled="$store.state.caller.loader"
            style="margin-left: 20px">
            SIP
          </b-form-checkbox>
          <span v-show="$store.state.caller.loader" class="spinner-border spinner-border-sm m-5px" role="status">
            <span class="sr-only">Loading...</span>
          </span>
          <caller-Toggler />
          <!-- <Caller :show="$store.state.caller.callerState" /> -->
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import useJwt from "@/auth/jwt/useJwt";
// import Caller from '@/views/component/caller.vue'
import CallerToggler from "@core/layouts/components/app-navbar/components/CallerToggler.vue";

export default {
  components: {
    // Caller,
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    CallerToggler,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  setup() {
    provide("openGroups", ref([]));
    return {
      resolveNavItemComponent,
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  mounted() {
    (function () {
      "use strict";
      // vertical direction
      (function resizableY() {
        const resizer = document.querySelector(".resizer-y");
        resizer.addEventListener("mousedown", onmousedown);
        resizer.addEventListener("touchstart", ontouchstart);
        // for mobile
        function ontouchstart(e) {
          e.preventDefault();
          resizer.addEventListener("touchmove", ontouchmove);
          resizer.addEventListener("touchend", ontouchend);
        }
        function ontouchmove(e) {
          e.preventDefault();
          const clientY = e.touches[0].clientY;
          const deltaY = clientY - (resizer._clientY || clientY);
          resizer._clientY = clientY;
          const t = resizer.previousElementSibling;
          const b = resizer.nextElementSibling;
          // UP
          if (deltaY < 0) {
            const h = Math.round(parseInt(getComputedStyle(t).height) + deltaY);
            t.style.flex = `0 ${h < 10 ? 0 : h}px`;
            b.style.flex = "1 0";
          }
          // DOWN
          if (deltaY > 0) {
            const h = Math.round(parseInt(getComputedStyle(b).height) - deltaY);
            b.style.flex = `0 ${h < 10 ? 0 : h}px`;
            t.style.flex = "1 0";
          }
        }
        function ontouchend(e) {
          e.preventDefault();
          resizer.removeEventListener("touchmove", ontouchmove);
          resizer.removeEventListener("touchend", ontouchend);
          delete e._clientY;
        }
        // for desktop
        function onmousedown(e) {
          e.preventDefault();
          document.addEventListener("mousemove", onmousemove);
          document.addEventListener("mouseup", onmouseup);
        }
        function onmousemove(e) {
          e.preventDefault();
          const clientY = e.clientY;
          const deltaY = clientY - (resizer._clientY || clientY);
          resizer._clientY = clientY;
          const t = resizer.previousElementSibling;
          const b = resizer.nextElementSibling;
          // UP
          if (deltaY < 0) {
            const h = Math.round(parseInt(getComputedStyle(t).height) + deltaY);
            t.style.flex = `0 ${h < 10 ? 0 : h}px`;
            b.style.flex = "1 0";
          }
          // DOWN
          if (deltaY > 0) {
            const h = Math.round(parseInt(getComputedStyle(b).height) - deltaY);
            b.style.flex = `0 ${h < 10 ? 0 : h}px`;
            t.style.flex = "1 0";
          }
        }
        function onmouseup(e) {
          e.preventDefault();
          document.removeEventListener("mousemove", onmousemove);
          document.removeEventListener("mouseup", onmouseup);
          delete e._clientY;
        }
      })();
    })();
  },
  methods: {
    clearLogout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      // this.$store.commit('caller/UPDATE_CALLER_STATE', false)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem("tabs");
      // // Remove userData from localStorage
      localStorage.removeItem("userData");
      this.$router.push({ name: "auth-login" });
    },
    logout() {
      this.clearLogout();
      this.$http
        .get("auth/logout")
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
#app {
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
.resizable-x,
.resizable-y {
  display: flex;
  overflow: hidden;
}
.resizable-x {
  height: 100%;
}
.resizable-y {
  flex-direction: column;
}
.resizer-x,
.resizer-y {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  padding: 1px;
}
.resizer-x {
  z-index: 2;
  cursor: col-resize;
}
.resizer-x::before,
.resizer-x::after {
  content: "";
  width: 2px;
  height: 16px;
  margin: 2px;
  background: lightgray;
}
.resizer-y {
  z-index: 1;
  cursor: row-resize;
  flex-direction: column;
}
.resizer-y::before {
  content: "";
  width: 100%;
  height: 1px;
  margin: 2px;
  background: lightgray;
}
.resizer-y::after {
  content: "";
  width: 40px;
  height: 4px;
  border-radius: 10px;
  margin: 2px;
  background: rgb(143, 141, 141);
}
.div2 {
  position: relative;
  overflow: hidden;
}
/* width */
.div1::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.div1::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.div1::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
.div1::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
