import profile from "./profile";
import useJwtData from "@/@core/auth/jwt/jwtDefaultConfig";

const findRoute = (route, access_routes) => {
  return access_routes.find((el) => el.route === route);
};

const userData = JSON.parse(
  localStorage.getItem(useJwtData.storageUserDataName)
);
let sortProfile = [];

if (userData) {
  profile.forEach((route) => {
    if (!route.children) {
      if (findRoute(route.route, userData.access_routes)) {
        sortProfile.push({ ...route });
      }
    } else {
      const filteredChildren = route.children.filter((el) =>
        findRoute(el.route, userData.access_routes)
      );
      if (filteredChildren.length > 0) {
        sortProfile.push({
          ...route,
          children: filteredChildren,
        });
      }
    }
  });
}

export default [...sortProfile];
